<template >
   <div :key="$store.state.keys.header">
       <md-toolbar class="md-primary">
           <div class="md-toolbar-row">
               <div class="md-toolbar-section-start">
                   <md-button class="md-icon-button md-raised" @click="showNavigation = true">
                       <img src="../assets/icon.png" width="30" alt="">
                   </md-button>

                   <span class="md-title">
						{{$store.state.active_tab}}
				   </span>
               </div>
               <div class="md-toolbar-section-end">
                   <md-badge class="md-primary" :md-content="calentar_events.length" md-dense
                             v-if="calentar_events.length > 0">
                       <md-button class="md-icon-button" @click="ShowCalendarActivity = true">
                           <md-icon>sync</md-icon>
                       </md-button>
                   </md-badge>

                   <md-button class="md-icon-button" @click="showSidepanel = true">
                       <md-icon>account_circle</md-icon>
                   </md-button>
               </div>
           </div>
       </md-toolbar>

       <md-drawer :md-active.sync="ShowCalendarActivity" md-right>
           <md-toolbar class="md-transparent" md-elevation="0">
               <span class="md-title" style="padding-top: 10px;">Actividad reciente</span>
           </md-toolbar>

           <md-list class="md-triple-line md-dense">
               <template v-for="cevent in calentar_events">
                   <md-list-item>
                       <div class="md-list-item-text">
                           <span>Reserva # {{cevent.bookings[0]}}</span>
                           <span>Acción: {{cevent.accion}}</span>
                           <p>Fecha : {{cevent.timestamp}}</p>
                       </div>
                   </md-list-item>
                   <md-divider class="md-inset"></md-divider>
               </template>
           </md-list>
       </md-drawer>

	   <MainManu />
	   <MenuUsuario/>

	   <router-view></router-view>
   </div>
</template>
<script>
	import MenuUsuario from './menus/MenuUsuario'
	import MainManu from "./menus/MainManu";
	import {empty} from "../helpers";

    export default {
        name : 'Header',
		components: {
			MenuUsuario,
			MainManu
		},
		methods :{
        	dispachMenu(item){
        		item.onclick();
			}
		},
		computed :{
			lang(){return this.$store.state.lang},
			showNavigation:{
				get(){ return this.$store.state.showNavigation},
				set(n,o){ this.$store.state.showNavigation = n; },
			},
			showSidepanel:{
				get(){ return this.$store.state.showSidepanel},
				set(n,o){ this.$store.state.showSidepanel = n; },
			}
		},
		beforeMount() {
            if(empty(this.$store.state.lang)){
                this.$store.dispatch('getServerData')
            }
			this.$store.state.showSidepanel = false;
			return this.$store.state.showNavigation = false;

		},
		data() {
            return {
                base_url : this.$store.state.base_url,
                establecimiento: this.$store.state.establecimiento,
				usuario: this.$store.state.usuario,
                menuVisible:false,
                ShowCalendarActivity: false,
                calentar_events : [],
                main_menu : [],
                categorias: {},
                token:true,
                showSnackbar: {
                    show: false,
                    text: '',
                    duration: 3000,
                }
            }
        }
    }

</script>

<style>


</style>
