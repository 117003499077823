<template>
	<md-drawer :md-active.sync = "showSidepanel" md-right>
		<md-toolbar :md-elevation="1">
			<div class="" style="display: flex; ">
				<md-icon class="md-size-2x">account_circle</md-icon>
				<div style="display: flex; flex-direction: column">
					<span class = "md-title">{{usuario.nombre}}</span>
					<small style = "margin-left: 8px">{{usuario.rol}}</small>
				</div>
			</div>
		</md-toolbar>
		<md-list md-expand-single>
			<template>
				<md-list-item
						v-for = "hotel in usuario.establecimientos_usuario"
						:key = "hotel.id_establecimiento"
						@click="loginAs(hotel.id_establecimiento)"
				>
					<md-icon>{{(hotel.id_establecimiento == establecimiento.id_establecimiento) ? 'done': 'login'}}</md-icon>
					<span class = "md-list-item-text">{{hotel.nombre_establecimiento}}</span>
				</md-list-item>
				<md-divider/>
			</template>

			<md-list-item  @click="toProfile">
				<md-icon>account_circle</md-icon>
				<span class = "md-list-item-text">{{lang.perfil_usuario}}</span>
			</md-list-item>

			<md-list-item md-expand>
				<md-icon>language</md-icon>
				<span class = "md-list-item-text">{{lang.idioma_preferido}}</span>
				<md-list slot = "md-expand">
					<md-list-item class = "md-inset" @click = "changeLang('es')">{{lang.espanol}}</md-list-item>
					<md-list-item class = "md-inset" @click = "changeLang('en')">{{lang.ingles}}</md-list-item>
					<md-list-item class = "md-inset" @click = "changeLang('fr')">{{lang.frances}}</md-list-item>
				</md-list>

			</md-list-item>

			<md-list-item  @click="logout" >
				<md-icon>lock</md-icon>
				<span class = "md-list-item-text">{{lang.cerrar_sesion}}</span>
			</md-list-item>
		</md-list>
		<Profile />
	</md-drawer>
</template>
<script>
import Profile from "./Profile";
import {request} from "../../helpers";

export default {
	name: 'MenuUsuario',
	components:{
		Profile
	},
	methods:{
		async logout(){
			this.$store.dispatch('logout');
		},
		toProfile(){
			this.showSidepanel  = false;
			this.$store.state.showProfile = true;
		},
		changeLang(lang){
			this.showSidepanel  = false;
			this.$store.state.active_lang = lang;
			this.$store.dispatch('getServerData')
		},
		loginAs(id){
			this.showSidepanel  = false;
			this.$store.dispatch('loginAs', {id});
		}
	},
	computed:{
		usuario(){ return this.$store.state.usuario},
		establecimiento(){return this.$store.state.establecimiento},
		lang(){return this.$store.state.lang},
		showSidepanel:{
			get(){ return this.$store.state.showSidepanel},
			set(n,o){ this.$store.state.showSidepanel = n; },
		}
	},
	data(){
		return {
			showProfile : false
		}
	}


}
</script>
<style lang="scss" scoped>
	.md-drawer {
		width: 250px;
		max-width: calc(100vw - 125px);
	}
</style>
