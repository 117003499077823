<template>
	<md-drawer :md-active.sync = "showSidepanel">
		<md-toolbar class="md-transparent" md-elevation="0" style="display: flex; flex-direction: column; padding-top: 20px">
			<div><img src="../../assets/logo.png" width="100" alt=""></div>
			<div class="md-title" style="padding-top: 10px;">{{establecimiento.nombre}}</div>
		</md-toolbar>

		<md-list md-expand-single>
			<md-list-item  :to="{name:'calendario'}">
				<md-icon>today</md-icon>
				<span class = "md-list-item-text">{{lang.calendario}}</span>
			</md-list-item>
			<md-list-item  :to="{name:'resumen'}">
				<md-icon>dvr</md-icon>
				<span class = "md-list-item-text">{{lang.resumen_dia}}</span>
			</md-list-item>
			<md-list-item  :to="{name:'listado'}">
				<md-icon>view_list</md-icon>
				<span class = "md-list-item-text">{{lang.lista_reservas}}</span>
			</md-list-item>

			<md-list-item  :to="{name:'appinfo'}" >
				<md-icon>info</md-icon>
				<span class = "md-list-item-text">{{lang.info_app}} </span>
			</md-list-item>

		</md-list>
	</md-drawer>
</template>
<script>
	export default {
		name: 'MainManu',
		methods:{

		},
		computed:{
			lang(){return this.$store.state.lang},
			showSidepanel:{
				get(){ return this.$store.state.showNavigation},
				set(n,o){ this.$store.state.showNavigation = n; },
			}
		},
		data(){
			return {
				establecimiento: this.$store.state.establecimiento,
				showProfile : false
			}
		}


	}
</script>
<style lang="scss" scoped>
	.md-drawer {
		width: 250px;
		max-width: calc(100vw - 125px);
	}
</style>
