<template>
	<md-dialog :md-active.sync="show" :md-fullscreen="false">
		<md-tabs md-dynamic-height>
			<md-tab :md-label="lang.perfil_usuario">
				<p style="text-align: center"><md-icon class="md-size-2x">account_circle</md-icon></p>
				<md-list class="md-double-line">
					<md-divider></md-divider>
					<md-list-item>
						<div class="md-list-item-text">
							<md-field>
								<label>{{lang.nombre}}</label>
								<md-input v-model="user.nombre" readonly></md-input>
							</md-field>
						</div>

						<md-button class="md-icon-button md-list-action" style="display: none">
							<md-icon>edit</md-icon>
						</md-button>
					</md-list-item>

					<md-list-item>
						<div class="md-list-item-text">
							<md-field>
								<label>{{lang.usuario}}</label>
								<md-input v-model="user.usuario" readonly></md-input>
							</md-field>
						</div>

						<md-button class="md-icon-button md-list-action" style="display: none">
							<md-icon>edit</md-icon>
						</md-button>
					</md-list-item>


					<md-list-item>
						<div class="md-list-item-text">
							<md-field>
								<label>{{lang.rol}}</label>
								<md-input v-model="user.rol" readonly></md-input>
							</md-field>
						</div>

						<md-button class="md-icon-button md-list-action" style="display: none">
							<md-icon>edit</md-icon>
						</md-button>
					</md-list-item>



					<md-list-item>
						<div class="md-list-item-text">
							<md-field>
								<label>{{lang.email}}</label>
								<md-input v-model="user.email" readonly></md-input>
							</md-field>
						</div>

						<md-button class="md-icon-button md-list-action" style="display: none">
							<md-icon>edit</md-icon>
						</md-button>
					</md-list-item>

				</md-list>
			</md-tab>
		</md-tabs>

		<md-dialog-actions>
			<md-button class="md-primary" @click="show = false">{{lang.cerrar}}</md-button>
		</md-dialog-actions>
	</md-dialog>
</template>

<script>

	export default {
		name: 'Profile',
		computed:{
			lang(){return this.$store.state.lang},
			show:{
				get(){return this.$store.state.showProfile},
				set(o,n){this.$store.state.showProfile = n},
				},
			user(){ return this.$store.state.usuario}
		},
		methods:{

		},
		data: () => ({

		}),
		mounted() {
			this.$nextTick(function () {

			});
		}
	}
</script>
